import { Routes as ReactRoutes } from 'react-router-dom';
import { Route } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import {
  MyCalls,
  MyCallsIFRAME,
  Reports,
  ReportsIFRAME,
  System,
  SystemIFRAME,
} from 'features/Views';

const Routes = () => {
  return (
    <MainLayout>
      <ReactRoutes>
        <Route path="/a/calls/myCalls" caseSensitive element={<MyCallsIFRAME />} />
        <Route path="/a/calls/MyCalls" caseSensitive element={<MyCalls />} />
        <Route path="/a/calls/reports" caseSensitive element={<ReportsIFRAME />} />
        <Route path="/a/calls/Reports" caseSensitive element={<Reports />} />
        <Route path="/a/calls/system" caseSensitive element={<SystemIFRAME />} />
        <Route path="/a/calls/System" caseSensitive element={<System />} />
      </ReactRoutes>
    </MainLayout>
  );
};

export default Routes;
