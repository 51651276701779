// Types
import { Assessment, Call, Settings } from '@mui/icons-material';
import { INavIcon } from '../types/types';

export const iconsData: INavIcon[] = [
  {
    title: 'global.calls',
    to: '/a/calls/myCalls',
    icon: <Call />,
  },
  {
    title: 'global.calls',
    to: '/a/calls/MyCalls',
    icon: <Call />,
    isDevelopment: true,
  },
  {
    title: 'global.reports',
    to: '/a/calls/reports',
    icon: <Assessment />,
  },
  {
    title: 'global.reports',
    to: '/a/calls/Reports',
    icon: <Assessment />,
    isDevelopment: true,
  },
  {
    title: 'global.system',
    to: '/a/calls/system',
    icon: <Settings />,
  },
  {
    title: 'global.system',
    to: '/a/calls/System',
    icon: <Settings />,
    isDevelopment: true,
  },
];
