import { useEffect, useState } from "react";

export function useOpenCallPanelWidth() {
  const [boxWidth, setBoxWidth] = useState('100%');

  const initialCallPopupIsPinned =
    localStorage.getItem("callPopupIsPinned") === "true";
  const [callPopupIsPinned, setCallPopupIsPinned] = useState<boolean>(
    initialCallPopupIsPinned !== null ? initialCallPopupIsPinned : true
  );

  const initialMainLayoutWidthReducer =
    localStorage.getItem("mainLayoutWidthReducer") === "true";
  const [mainLayoutWidthReducer, setMainLayoutWidthReducer] = useState<boolean>(
    initialMainLayoutWidthReducer !== null
      ? initialMainLayoutWidthReducer
      : true
  );

  useEffect(() => {
    const handleCallPopupIsPinnedChangedChanged = (event) => {
      const newState = event.detail;
      setCallPopupIsPinned(newState);
    };

    window.addEventListener("callPopupIsPinnedChanged", handleCallPopupIsPinnedChangedChanged);

    return () => {
      window.removeEventListener("callPopupIsPinnedChanged", handleCallPopupIsPinnedChangedChanged);
    };
  }, []);

  useEffect(() => {
    const handleMainLayoutWidthReducerChanged = (event) => {
      const newMainLayoutWidthReducer = event.detail;
      setMainLayoutWidthReducer(newMainLayoutWidthReducer);
    };
  
    window.addEventListener(
      "mainLayoutWidthReducerChanged",
      handleMainLayoutWidthReducerChanged
    );
  
    return () => {
      window.removeEventListener(
        "mainLayoutWidthReducerChanged",
        handleMainLayoutWidthReducerChanged
      );
    };
  }, []);

  useEffect(() => {
    const panelCallReduced = mainLayoutWidthReducer && callPopupIsPinned;
    const closedCallPinned = !mainLayoutWidthReducer && callPopupIsPinned;

    const newBoxWidth = panelCallReduced ? "calc(100% - 270px)" : closedCallPinned ? "100%" : "100%";
    setBoxWidth(newBoxWidth);
  }, [mainLayoutWidthReducer, callPopupIsPinned]);

  return { boxWidth, callPopupIsPinned, mainLayoutWidthReducer };
}
